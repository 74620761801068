import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { rhythm } from '../../utils/typography'
import Image from 'gatsby-image'

class Index extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title="Simple HIIT Timer App"
          keywords={[
            `HIIT`,
            `Timer`,
            `app`,
            `iOS`,
            `fitness`,
            `appstore`,
            `mobile`,
            `workout`,
            `free`,
          ]}
        />
        <h1>Easy HIIT Timer</h1>
        <Image
          fluid={data.avatar.childImageSharp.fluid}
          alt={`Easy HIIT Timer`}
          style={{
            display: `block`,
            margin: `${rhythm(1.5)} auto`,
            maxWidth: rhythm(10),
          }}
        />
        <p>
          Easy to use High Intensity Interval Training timer. Just set your
          workout duration, workouts per set, and number of sets and it will
          give you visual, audio, and haptic cues so you know when it's time to
          rest and when to start a new exercise.
        </p>
        <p>
          Using this app is completely free. Even if premium paid features are
          added down the road, the core timer functionality will remain
          accessible at no cost.
        </p>
        <p>
          To report any issues or submit feature requests, plase send an email
          to <a href="mailto:hiit@eduardo.dev">hiit@eduardo.dev</a>.
        </p>
        <a
          href="https://apps.apple.com/us/app/easy-hiit-timer/id1510385510?mt=8"
          style={{
            display: `block`,
            marginLeft: `auto`,
            marginRight: `auto`,
            overflow: `hidden`,
            background: `url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2011-08-31&kind=iossoftware&bubble=ios_apps) no-repeat`,
            backgroundPosition: `center`,
            width: `135pt`,
            height: `40pt`,
          }}
        ></a>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/hiit-icon.png/" }) {
      childImageSharp {
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`
